import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store/index.js';
import { Button,Form, Field, CellGroup,Uploader,Popup,Area,Empty } from 'vant';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import 'vant/lib/index.css';
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)
Vue.use(Antd);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Uploader);
Vue.use(Popup);
Vue.use(Area);
Vue.use(Empty);


import './permission'; // 全局权限
import direction from './direction';
Vue.use(direction);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
