import Vue from "vue";
import VueRouter from "vue-router";
import PagesRouter from "./pages/index";
import ViewsRouter from "./views/index";
import viewRouters from "@/router/views";
import store from "@/store";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  console.log("push", location);
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [...PagesRouter, ...ViewsRouter];

const router = new VueRouter({
  routes,
});
const menuList = store.getters.menuList.permissions;
let menuListMap = [];
if (menuList) {
  menuListMap = menuList.map((item) => {
    return item.split(":")[0];
  });
}
let showList = [];
viewRouters.some((item) => {
  item.children.map((citem) => {
    if (menuListMap.indexOf(citem.meta.menuName) > -1) {
      showList.push(citem.path);
    }
  });
});
// 当前页面路径
router.beforeEach((to, from, next) => {
  console.log('showList',showList);
  console.log('to.path',to.path);
  if(to.path=='/login'||to.path=='/apply'){
    next()
  }else if (!showList) {
    next("/login");
  } else {
    console.log('store.getters.token',store.getters.token);
    if(!store.getters.token){
      next('/login');
    }else if (showList.indexOf(to.path) === -1) {
      if(showList.includes(from.path)){
        next(from.path);
      }else if(from.path=='/login'){
        next(showList[0]);
      }else{
        next(showList[0]);
      }
    } else {
      next();
    }
  }
});
export default router;
//http://localhost:8080/#/postageTemplate/manage
