import Layout from "@/pages/index";
// import subLayout from "@/pages/subLayout.vue";
/**
 * views router.
 */
// import store from "@/store";
// const menuList = store.getters.menuList.permissions;
// console.log("router_menuList", menuList);
// let menuListMap = [];
// if (menuList) {
//   menuListMap = menuList.map((item) => {
//     return item.split(":")[0];
//   });
//   console.log("menuListMap", menuListMap);
// }

const allRouterList = [
  {
    path: "/supplier",
    component: Layout,
    children: [
      {
        path: "/supplier/manage",
        name: "供应商列表",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/supplier"),
        meta: {
          icon: "shop",
          menuName: "supplier",
          show: true,
        },
      },
    ],
  },
  {
    path: "/storehouse/manage",
    component: Layout,
    children: [
      {
        path: "/storehouse/manage",
        name: "仓库管理",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/storehouse"),
        meta: {
          icon: "shop",
          menuName: "warehouse",
          show: true,
        },
      },
    ],
  },
  {
    path: "/channel/manage",
    component: Layout,
    children: [
      {
        path: "/channel/manage",
        name: "渠道管理",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/channel"),
        meta: {
          icon: "shop",
          menuName: "channel",
          show: true,
        },
      },
    ],
  },
  {
    path: "/shop/manage",
    component: Layout,
    children: [
      {
        path: "/shop/manage",
        name: "门店管理",
        component: () => import(/* webpackChunkName: "views" */ "@/views/shop"),
        meta: {
          icon: "shop",
          menuName: "store",
          show: true,
        },
      },
    ],
  },
  {
    path: "/postageTemplate/manage",
    component: Layout,
    children: [
      {
        path: "/postageTemplate/manage",
        name: "配送管理",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/postageTemplate"),
        meta: {
          icon: "shop",
          menuName: "distribution",
          show: true,
        },
      },
    ],
  },
  {
    path: "/goods/manage",
    component: Layout,
    children: [
      {
        path: "/goods/manage",
        name: "货品管理",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/goods"),
        meta: {
          icon: "shop",
          menuName: "goods",
          show: true,
        },
      },
      {
        path: "/goods/add",
        name: "添加商品",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/goods/add.vue"),
        meta: {
          icon: "shop",
          menuName: "goods",
          show: false,
        },
      },
    ],
  },
  {
    path: "/order/manage",
    component: Layout,
    children: [
      {
        path: "/order/manage",
        name: "采购单管理",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/order"),
        meta: {
          icon: "shop",
          menuName: "purchase_order",
          show: true,
        },
      },
    ],
  },
  {
    path: "/discount/manage",
    component: Layout,
    children: [
      {
        path: "/discount/manage",
        name: "优惠管理",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/discount"),
        meta: {
          icon: "shop",
          menuName: "marketing",
          show: true,
        },
      },
    ],
  },
  {
    path: "/delivery/manage",
    component: Layout,
    children: [
      {
        path: "/delivery/manage",
        name: "发货管理",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/delivery"),
        meta: {
          icon: "shop",
          menuName: "delivery",
          show: true,
        },
      },
    ],
  },
  {
    path: "/role/manage",
    component: Layout,
    children: [
      {
        path: "/role/list",
        name: "角色管理",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/permission/role"),
        meta: {
          icon: "shop",
          menuName: "role",
          show: true,
        },
      },
    ],
  },
  {
    path: "/account/manage",
    component: Layout,
    children: [
      {
        path: "/account/list",
        name: "账户管理",
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/permission/account"
          ),
        meta: {
          icon: "shop",
          menuName: "account",
          show: true,
        },
      },
    ],
  },
];
export default allRouterList;
