export default [
  {
    path: "/login",
    name: "登录",
    component: () => import(/* webpackChunkName: "pages" */ "@/pages/login"),
    meta: {
      isAuth: false,
    },
  },
  {
    path: "/apply",
    name: "门店申请表",
    component: () => import(/* webpackChunkName: "pages" */ "@/pages/apply"),
    meta: {
      isAuth: false,
    },
  },
  {
    path: "*",
    redirect: "/supplier/manage",
  },
];
