import Vue from 'vue';
import Vuex from 'vuex';
import { setToken, removeToken } from '@/utils/auth';
import { setStore, getStore, removeStore } from '@/utils/store';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: getStore({ name: 'userInfo' }) || {},
    token: getStore({ name: 'token' }) || ''
  },
  getters: {
    menuList: (state) => {
      const { userInfo } = state;
      const { userPermission } = userInfo;
      return {
        permissions:userPermission?.permissionList
      };
    },
    token: (state) => {
      return state.token;
    }
  },
  mutations: {
    SET_TOKEN: (state, token, expiresTime) => {
      state.token = token;
      setToken(token, expiresTime);
      setStore({ name: 'token', content: token });
    },

    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
      setStore({ name: 'userInfo', content: userInfo });
    }
  },

  actions: {
    // 注销session
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '');
        commit('SET_USER_INFO', {});
        removeStore({ name: 'token' });
        removeStore({ name: 'userInfo' });
        removeToken();
        resolve();
      });
    }
  },
  modules: {}
});
