<template>
  <a-layout-sider :collapsed="collapsed" :trigger="null" collapsible>
    <div class="logo" />

    <a-menu
      theme="dark"
      mode="inline"
      :default-open-keys="openKeys"
      :default-selected-keys="[$route.path]"
    >
      <template v-for="menu in menus">
        <a-menu-item v-if="!menu['children']" @click="toPage" :key="menu.path">
          <a-icon :type="menu.meta.icon" />
          <span>{{ menu.name }}</span>
        </a-menu-item>

        <a-sub-menu v-else :title="menu.name" :key="menu.path">
          <a-menu-item
            v-for="child in menu.children"
            :key="child.path"
            @click="toPage"
          >
            <a-icon :type="child.meta.icon" />
            <span>{{ child.name }}</span>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import viewRouters from "@/router/views";
import store from '@/store';


export default {
  name: "SideMenu",
  props: {
    collapsed: Boolean,
  },


  computed: {
    openKeys() {
      const { matched } = this.$route;
      const { path } = matched[0];
      return [path];
    },

    menus() {
      return this.getRoutes(viewRouters);
    // return viewRouters
    },
  },

  methods: {
    toPage({ key }) {
      this.$router.push(key);
    },

    
    getRoutes(routes) {
      const menuList = store.getters.menuList.permissions;
      if(!menuList){
        // 前往登陆页面 
        this.$router.push('/login');
        return 
      }
      const menuListMap = menuList.map(item=>{return item.split(':')[0]});

      let arr = [];
      routes.forEach((r) => {
        if (r.children) {
          r.children.forEach((c) => {
            if(c.meta.show && menuListMap.includes(c.meta.menuName)){
              arr.push(c)
            }
          });
        } else {
          arr.push({
            name: r.name,
            path: r.path,
          });
        }

      });
      console.log('arr',arr);
      return arr;
    },
  },
};
</script>

<style>
.ant-layout-sider {
  height: 100vh;
  overflow-y: hidden;
}
</style>

<style lang="less" scoped>
.weapp-code {
  //position: absolute;
  //left: 20px;
  //right: 20px;
  //bottom: 20px;
  margin: 20px auto 0;
  .title {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .app-code {
    display: block;
    width: 120px;
    height: auto;
    margin: 0 auto;
  }
}
</style>
