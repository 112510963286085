<template>
  <a-layout id="pages-layout">
    <Menu :collapsed="collapsed" />
    <a-layout>
      <top :collapsed="collapsed" @change="onMenuChange" />
      <a-layout-content class="main-container" :style="{ height: h }">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import Top from "./components/top.vue";
import Menu from "./components/menu.vue";
export default {
  name: "indexPage",
  components: {
    Top,
    Menu,
  },
  data() {
    return {
      collapsed: false,
      h: 0,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.mainHeight();
    });

    this.mainHeight();
  },

  methods: {
    onMenuChange(value) {
      this.collapsed = value;
    },

    mainHeight() {
      const h = document.documentElement.clientHeight;
      this.h = h - 64 - 24 * 2 + "px";
    },
  },
};
</script>

<style>
.main-container {
  padding: 16px;
  margin: 24px 16px;
  background-color: #ffffff;
  overflow-y: auto;
}

#pages-layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#pages-layout .trigger:hover {
  color: #1890ff;
}

#pages-layout .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.form-group {
  margin-top: 10px !important;
  background-color: #f6f7f9;
  padding: 10px !important;
  margin-bottom: 10px;
}
</style>
