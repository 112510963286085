<template>
  <a-modal
    title="修改密码"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <!-- 旧密码、新密码、确认密码 -->
    <a-form
      :form="form"
      ref="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 12 }"
    >
      <a-form-item label="旧密码" hasFeedback>
        <a-input
          style="width: 300px"
          v-decorator="[
            'oldPassword',
            {
              rules: [{ required: true, message: '请输入旧密码' }],
            },
          ]"
          type="password"
        />
      </a-form-item>
      <a-form-item label="新密码" hasFeedback>
        <a-input-password
          style="width: 300px"
          v-decorator="[
            'newPassword',
            {
              rules: [
                { required: true, message: '请输入新密码' },
                {
                    // 密码是8-16位长度，密码必须包含至少一个大写字母、一个小写字母、一个数字和一个特殊字符
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_\-+=\[\]{}|;:.,<>?/])[A-Za-z\d~!@#$%^&*()_\-+=\[\]{}|;:.,<>?/]{8,16}$/,
                    message: '密码必须是8-16位长度，且包含至少一个大写字母、一个小写字母、一个数字和一个特殊字符'
                }
            ],
            },
          ]"
          type="password"
        />
      </a-form-item>
      <a-form-item label="确认密码" hasFeedback>
        <a-input-password
          style="width: 300px"
          v-decorator="[
            'confirmPassword',
            {
              rules: [
                { required: true, message: '请再次输入新密码' },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            },
          ]"
          type="password"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
export default {
  name: "changePassword",
  data() {
    return {
      visible: false,
      loading: false,
      form: this.$form.createForm(this),
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    handleOk() {
      this.$refs.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.visible = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("newPassword")) {
        callback("两次输入的密码不一致!");
      } else {
        callback();
      }
    },
  },
};
</script>
