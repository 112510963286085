<template>
  <a-layout-header class="top">
    <update-psw ref="updatePswRef"></update-psw>

    <span></span>
    <div class="user-info">
      <a-dropdown>
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          <span>{{ userInfo.administratorFlag?'God Mode': userInfo.userPermission.roleList[0].roleName||'未读取到身份' }}：{{ userInfo.username||'未读取到账户名称' }} </span>
          <a-icon type="down" />
        </a>
        <a-menu slot="overlay" @click="onMenuItemTap">
          <a-menu-item key="out-login">
            <span>退出登录</span>
          </a-menu-item>
          <a-menu-item key="update-password">
            <span>修改密码</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>

  </a-layout-header>
</template>

<script>
import { mapState } from "vuex";
import updatePsw from "./updatePsw.vue";
export default {
  name: "top-header",
  props: {
    collapsed: Boolean,
  },
  components: {
    updatePsw,
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  mounted() {
    // console.log('userInfo', this.userInfo);
  },
  methods: {
    leftMenuChange() {
      this.$emit("change", !this.collapsed);
    },

    onMenuItemTap({ key: type }) {
      if (type === "update-password") {
        this.$refs.updatePswRef.show();
      } else if (type === "out-login") {
        this.$store
          .dispatch("FedLogOut")
          .then(() => this.$router.push("/login"));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  padding: 0;
  background-color: #ffffff;
  .user-info {
    margin-right: 20px;
  }
}
</style>
